<template>
  <v-dialog v-model="open" persistent max-width="600px">
    <v-form v-model="formIsValid" @submit.prevent="save">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-alert
                  light
                  :value="saveError ? true : false"
                  type="error"
                  transition="scale-transition"
                  >{{ saveError }}</v-alert
                >
              </v-col>
              <v-col cols="12">
                <BaseTextField :value.sync="limit.value" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="close">{{ $t('close') }}</v-btn>
          <v-btn color="primary" type="submit" text :disabled="!formIsValid">{{
            $t('save')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import BaseTextField from '@/components/inputs/BaseTextField';

export default {
  props: {
    showForm: Boolean,
    limitData: Object,
  },

  components: { BaseTextField },

  data: () => ({
    formIsValid: false,
    limit: {},
  }),

  computed: {
    ...mapState({
      saveError: (state) => state.expenses.saveError,
    }),

    open: {
      get() {
        return this.showForm;
      },
      set(value) {
        this.$emit('update:showForm', value);
      },
    },
  },

  methods: {
    ...mapActions(['updateLimit']),

    close() {
      this.open = false;
      this.$store.commit('setSaveError', null);
    },

    async save() {
      await this.updateLimit({ limit: this.limit });
      this.handleSaveError();
    },

    handleSaveError() {
      !this.saveError ? (this.open = false) : (this.open = true);
    },
  },

  created() {
    this.limit = { ...this.limitData };
  },
};
</script>
