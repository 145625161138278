<template>
  <v-text-field
    :label="$t('value')"
    name="value"
    type="text"
    :rules="valueRules"
    v-model="valueData"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    value: [Number, String],
  },

  data: function () {
    return {
      valueRules: [(data) => !!data || this.$t('formFieldEmptyRule')],
    };
  },

  computed: {
    valueData: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:value', value);
      },
    },
  },
};
</script>
